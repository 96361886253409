.disruptions-filter-types {
  .field,
  .list {
    position: relative;

    .button {
      margin-top: 25px;
    }

    .item {
      div {
        width: 15px;
        height: 15px;
        border-radius: 8px;
        position: absolute;
      }

      span {
        padding-left: 20px;
      }
    }
  }
}
