.rs-popup {
  position: absolute;
  background: white;

  > div {
    > div:last-child {
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
