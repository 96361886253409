/* stylelint-disable-next-line import-notation */
@import "~react-spatial/themes/default/variables";
/* stylelint-disable-next-line import-notation */
@import "~react-spatial/components/Popup/Popup";

.app {
  position: relative;
}

.rs-map {
  height: 100vh;
  width: 100vw;
}

.rs-copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
}
